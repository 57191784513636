<template>
  <v-container>
    <div>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg2 xl2 grow pa-1 offset-lg-5 offset-xl-5>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="เดือน"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" type="month" locale="th" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  monthChanged();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-flex>
      </v-layout>
    </div>
    <v-card>
      <v-card-title>
        <v-icon large> mdi-file-document </v-icon>
        รายได้ลูกค้ารวมทุกบริการ (ตาม CA)
      </v-card-title>
      <v-card-text>
        <!-- <v-btn
            small
            color="secondary"
            class="ma-2"
            outlined
            @click="exportToExcel()"
          >
            <v-icon> mdi-microsoft-excel </v-icon>
            Export to Excel
          </v-btn> -->
        <v-data-table
          :headers="headers"
          :items="PriceSumByCADatas"
          :loading="loading"
          loading-text="Loading..."
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.priceSum`]="{ item }">
            <span>
              {{
                new Intl.NumberFormat("th-TH", {
                  style: "currency",
                  currency: "THB",
                }).format(item.priceSum)
              }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/crm-data/' + item.id + '/show'"
            >
              <v-icon> mdi-magnify </v-icon>
              Detail
            </v-btn>
            <!-- <v-btn
                small
                color="primary"
                class="ma-2"
                outlined
                :to="'/customer/' + item.id + '/edit'"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
                Edit
              </v-btn> -->
            <!-- <v-btn
              small
              color="primary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/delete'"
            >
              <v-icon>
                mdi-delete
              </v-icon>
              Delete
            </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
  <script>
import axios from "axios";
import moment from "moment";

moment.locale("th");

export default {
  name: "PriceSumByCAReport",
  components: {},
  props: {},
  data() {
    return {
      modal: false,
      date: new Date().toISOString().substr(0, 7),
      options: {},
      PriceSumByCADatas: [],
      loading: false,
      headers: [
      {
          text: "เดือน",
          value: "month",
          sortable: false,
          align: "center",
        },      
        {
          text: "ส่วนบริการลูกค้า",
          value: "officeSector",
          sortable: false,
          align: "center",
        },
        {
          text: "CA",
          value: "CA",
          sortable: false,
          align: "center",
        },
        {
          text: "ชื่อ",
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: "จำนวนวงจร",
          value: "circuitCount",
          sortable: false,
          align: "center",
        },
        {
          text: "รวมรายได้ทุกบริการ",
          value: "priceSum",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {},
  created() {
    this.loaded = false;
    this.hasSeries = false;
    this.getDataFromAPI();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          month: this.date,
          // page: this.options.page,
          // per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/report/price-sum-by-ca", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log(response.data);
          this.PriceSumByCADatas = response.data;
          // this.totalPriceSumByCADatas = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    monthChanged() {
      // console.log(this.date)
      this.loaded = false;
      this.hasSeries = false;
      this.getDataFromAPI();
    },
    // exportToExcel() {
    //   this.loading = true;
    //   const queryParams = {
    //     params: {
    //       search: this.search,
    //     },
    //     responseType: "arraybuffer",
    //   };
    //   axios
    //     .get("/export-to-excel/customer-use-service", queryParams)
    //     .then((response) => {
    //       // console.log(response);
    //       let blob = new Blob([response.data], {
    //         type: response.headers["content-type"],
    //       });
    //       let link = document.createElement("a");
    //       link.href = window.URL.createObjectURL(blob);
    //       // console.log(response.headers);
    //       link.download = "BCRM_บริการที่ลูกค้าใช้งาน.xlsx";
    //       link.click();
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
  